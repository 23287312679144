.header-contacts {
    font-family: "Subaru-Regular", sans-serif;
}
.about-plitka {
    width: 771px;
}
.section-01 {
    padding-top: 60px;
}
.section-01-about {
    padding-top: 40px;
}
